<template>
  <div>
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <div v-if="orderData != null">

      <b-row class="row-eq-height">
        <b-col class="mb-1" md="6">
          <b-card :title="$t('Order_Show.Order')" class="h-100">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.ID") }}</th>
                  <td class="text-right">{{ orderData.id }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Code") }}</th>
                  <td class="text-right">{{ orderData.code }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Status") }}</th>
                  <td class="text-right">
                    <b-badge variant="warning">
                      {{ orderStatus }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th class="text-left">التصنيف</th>
                  <td class="text-right">
                    <b-badge v-if="orderData.is_portal" variant="primary">
                      بيع منصة
                    </b-badge>
                    <b-badge v-else variant="secondary">
                      بيع مباشر
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Type") }}</th>
                  <td class="text-right">{{ orderData.type }}</td>
                </tr>
  
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Note") }}</th>
                  <td class="text-right">{{ orderData.note }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Created_at") }}</th>
                  <td class="text-right">{{ orderData.created_at }}</td>
                </tr>
                <tr v-if="delUser">
                  <th class="text-left">{{ $t("Order_Show.Updated_at") }}</th>
                  <td class="text-right">{{ orderData.updated_at }}</td>
                </tr>
                <tr v-else>
                  <th class="text-left">Deleted Date</th>
                  <td class="text-right">{{ orderData.deleted_at }}</td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-card class="h-100" footer-tag="footer">
            <div v-if="delUser" class="d-flex justify-content-center">
              <b-button
                block
                v-if="shipPDF != null"
                v-b-modal.modal-xl
                variant="primary "
                class=" mr-1 mt-1"
              >
                {{ $t("Order_Show.Invoice") }}
              </b-button>
              <b-button
                block
                v-if="!orderData.is_portal && orderData.orderstatus.slug == 'processing'"
                @click="refresh"
                variant="outline-secondary"
                class=" mr-1 mt-1"
              >
                تحديث معاملة الطلب
              </b-button>
              <b-modal
                id="modal-xl"
                ok-only
                ok-title="أغلاق"
                centered
                size="xl"
                :title="$t('Order_Show.Invoice')"
              >
                <object>
                  <iframe
                    id="pdfID"
                    type="text/html"
                    width="100%"
                    height="1000px"
                    sc
                    :src="'data:application/pdf;base64,' + this.shipPDF"
                  />
                </object>
              </b-modal>
              <b-button
                block
                v-if="orderData.valid_to_change_status"
                :to="{ path: '/orders/Update/Status/' + orderData.id }"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                {{ $t("Order_Show.Edit_Status") }}
              </b-button>
            </div>
  
            <hr />
            <div
              v-if="delUser"
              class=" d-flex justify-content-around text-center"
            >
              <div class="date-section ">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Created_at") }} :
                </h6>
                <p class="mb-0">
                  {{ orderData.created_at }}
                </p>
              </div>
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Updated_at") }} :
                </h6>
                <p class="mb-0">
                  {{ orderData.updated_at }}
                </p>
              </div>
            </div>
            <div v-else class=" d-flex justify-content-around text-center">
              <div class="date-section ">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Created_at") }} :
                </h6>
                <p class="mb-0">
                  {{ orderData.created_at }}
                </p>
              </div>
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">
                  deleted at :
                </h6>
                <p class="mb-0">
                  {{ orderData.deleted_at }}
                </p>
              </div>
            </div>
            <template
              v-if="!orderData.is_refund && orderData.refund != null"
              #footer
            >
              <em>
                <b-row>
                  <b-col cols="12">
                    <b-button
                      block
                      variant="outline-warning"
                      @click="
                        $router.push('/orders/refund/show/' + orderData.refund.id)
                      "
                      >Show Refund Data</b-button
                    >
                  </b-col>
                </b-row>
              </em>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="!orderData.is_portal && orderData.orderstatus.slug == 'delivered' && orderData.e_contract != null" cols="12">
          <b-card title="بيانات العميل">
            <b-row v-if="orderData">
              <b-col md="6">
                <table class="table table-bordered" >
                  <tbody>
                    <tr>
                      <th class="text-left">الأسم</th>
                      <td
                        class="text-right"
                        v-if="orderData.e_contract.customer_name"
                      >
                        {{ orderData.e_contract.customer_name }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-left">رقم البطاقة</th>
                      <td class="text-right" v-if="orderData">
                        {{ orderData.e_contract.customer_id_number }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-left">الصورة الأمامية للبطاقة</th>
                      <td
                        class="text-right"
                        v-if="orderData.e_contract.id_front_image == null"
                      >
                        لا يوجد
                      </td>
                      <td class="text-right" v-else>
                        <a
                          :href="orderData.e_contract.id_front_image"
                          target="_blank"
                          rel="noopener noreferrer"
                          >فتح</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <th class="text-left">الصورة الخلفية للبطاقة</th>
                      <td
                        class="text-right"
                        v-if="orderData.e_contract.id_back_image == null"
                      >
                        لا يوجد
                      </td>
                      <td class="text-right" v-else>
                        <a
                          :href="orderData.e_contract.id_back_image"
                          target="_blank"
                          rel="noopener noreferrer"
                          >فتح</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="6">
                <validation-observer ref="simpleRules">
                  <b-row>
                    <!-- Id front picture -->
                    <b-col
                      v-if="orderData.e_contract.id_front_image == null"
                      md="6"
                    >
                      <b-form-group
                        label="الصورة الأمامية للبطاقة"
                        label-for="الصورة الأمامية للبطاقة"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="الصورة الأمامية للبطاقة"
                          rules="required"
                        >
                          <b-form-file
                            v-model="ProfilePictureBase642"
                            @change="uploadImage2"
                            id="ProfilePictureBase642"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Id back picture -->
                    <b-col
                      v-if="orderData.e_contract.id_back_image == null"
                      md="6"
                    >
                      <b-form-group
                        label="الصورة الخلفية للبطاقة"
                        label-for="الصورة الخلفية للبطاقة"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="الصورة الخلفية للبطاقة"
                          rules="required"
                        >
                          <b-form-file
                            v-model="ProfilePictureBase643"
                            @change="uploadImage3"
                            id="ProfilePictureBase643"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="orderData.e_contract.id_back_image == null || orderData.e_contract.id_front_image == null" cols="12">
                      <b-button variant="secondary" @click="saveId" block>
                        حفظ الصور</b-button
                      >
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col v-if="items.length > 0" class="" cols="12">
          <b-card :title="$t('Order_Show.Item_List')" style="overflow:scroll">
            <b-table striped hover :items="items" :fields="fields">
              <template #cell(CompareReports)="items">
                <b-button
                  v-b-modal.modal-scrollable
                  @click="selectRow(items.index, items.item.identical)"
                  variant="success"
                  v-if="items.value.length > 1"
                  >Show Report</b-button
                >
                <!--:to="{name: 'OrderShowCompareReports', params: { selling: items.value[0].type == 'selling' ? items.value[0].serial_number : items.value[1].serial_number, buying: items.value[1].type == 'buying' ? items.value[1].serial_number : items.value[0].serial_number} }"-->
  
                <h1 v-else>-</h1>
              </template>
  
              <template #cell(status)="data">
                <b-badge variant="warning">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(is_portal)="data">
                <b-badge v-if="data.value" variant="primary">
                  بيع منصة
                </b-badge>
                <b-badge v-else variant="secondary">
                  بيع مباشر
                </b-badge>
              </template>
              <template #cell(identical)="data">
                <img
                  :src="
                    data.value == false
                      ? require('@/assets/images/remove.png')
                      : require('@/assets/images/check.png')
                  "
                  alt="alternate"
                  title="tit"
                  width="20px"
                  height="20px"
                />
              </template>
            </b-table>
          </b-card>
          <b-modal
            size="lg"
            id="modal-scrollable"
            scrollable
            :title="'Compare Result - (Identical: ' + identicalType + ')'"
            cancel-title="Close"
            cancel-variant="outline-secondary"
          >
            <b-table striped hover :items="compareItems" :fields="compareFields">
              <template #cell(identical)="data">
                <img
                  :src="
                    data.value == false
                      ? require('@/assets/images/remove.png')
                      : require('@/assets/images/check.png')
                  "
                  alt="alternate"
                  title="tit"
                  width="20px"
                  height="20px"
                />
              </template>
            </b-table>
          </b-modal>
        </b-col>
        <!-- <b-col v-if="bundles.length > 0" cols="12">
                  <b-card title="Bundles">
                      <b-table-simple hover small responsive>
                          <b-thead head-variant="light">
                              <b-tr>
                                  <b-th colspan="4">
                                      {{$t('Order_Show.Bundles')}}
                                  </b-th>
                                  <b-th colspan="1">
                                      {{$t('Order_Show.Bundle_Quantity')}}
                                  </b-th>
                                  <b-th colspan="1">
                                       {{$t('Order_Show.Status')}}
                                  </b-th>
                                  <b-th colspan="5">
                                       {{$t('Order_Show.Item')}}
                                  </b-th>
                                  <b-th colspan="1">
                                       {{$t('Order_Show.Item_Quantity')}}
                                  </b-th>
                              </b-tr>
                          </b-thead>
                          <b-tbody v-for="(item,index) in bundles">
                              <b-tr>
                                  <b-th colspan="4">
                                      {{item.title}}
                                  </b-th>
                                  <b-td colspan="1">
                                      {{item.quantity}}
                                  </b-td>
                                  <b-td colspan="1">
                                      <b-badge variant="primary">
                                          {{item.item_status_slug}}
                                      </b-badge>
                                  </b-td>
                                  <b-th colspan='5'>
                                      <div v-for="(itme, index) in item.variants">
                                          {{itme.name}}
                                      </div>
                                  </b-th>
                                  <b-td colspan='1'>
                                      <div v-for="(itme, index) in item.variants">
                                          {{itme.quantity}}
                                      </div>
                                  </b-td>
                              </b-tr>
                          </b-tbody>
                      </b-table-simple>
                  </b-card>
              </b-col> -->
      </b-row>
      <b-row>
        <!-- User Info: Left col -->
        <b-col md="6" class="d-flex justify-content-between flex-column mb-1">
          <b-card
            v-if="orderData.vendor != null"
            :title="$t('Order_Show.Vendor')"
            class="h-100 cardH"
          >
            <!-- User Avatar & Action Buttons -->
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.ID") }}</th>
                  <td class="text-right">{{ idV }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.First_Name") }}</th>
                  <td class="text-right">{{ firstnameV }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Last_Name") }}</th>
                  <td class="text-right">{{ lastnameV }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Email") }}</th>
                  <td class="text-right">{{ emailV }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Phone") }}</th>
                  <td class="text-right">{{ phoneV }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Store_Name") }}</th>
                  <td class="text-right">{{ storename }}</td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card :title="$t('Order_Show.Order_Total')">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Total") }}</th>
                  <th class="text-right">{{ orderData.total }} جنية</th>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Tax") }}</th>
                  <td class="text-right">{{ orderData.tax }} جنية</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Fees") }}</th>
                  <td class="text-right">{{ orderData.fees }} جنية</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Discount") }}</th>
                  <td class="text-right">{{ discount }}</td>
                </tr>
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Shipping_Price") }}</th>
                  <td class="text-right">{{ orderData.shipping_price }} جنية</td>
                </tr>
  
                <tr>
                  <th class="text-left">{{ $t("Order_Show.Total_Paid") }}</th>
                  <th class="text-right">{{ orderData.total_paid }} جنية</th>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
      </b-row>
      <b-card v-if="shipData != null" title="AirWay Bill">
        <b-row>
          <b-col class="mt-3 ml-5" cols="12">
            <app-timeline>
              <app-timeline-item
                v-for="(item, index) in shipData.TrackingLogDetails"
                :key="index"
                variant="primary"
              >
                <div class="">
                  <h5>{{ item.Remarks }}</h5>
                  <p>{{ item.ActivityDate }}</p>
                </div>
              </app-timeline-item>
            </app-timeline>
          </b-col>
        </b-row>
      </b-card>
      <b-row> </b-row>
    </div>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";


import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { required } from "@validations";
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    CoolLightBox,
    
  },
  data() {
    return {
      required,
      ProfilePictureBase642: [],
      ProfilePictureBase643: [],
      per: null,
      orderData: null,
      errors_back: [],
      showDismissibleAlert: false,
      id: 0,
      status: "",
      area: "",
      delUser: true,
      imgIndex: null,
      profileArr: [],
      firstnameV: "",
      firstnameU: "",
      lastnameV: "",
      loggerNote: {
        note: "",
      },
      lastnameU: "",
      addressV: "",
      addressU: "",
      phoneV: "",
      phoneU: "",
      emailV: "",
      emailU: "",
      discount: "",
      storename: "",
      profilePicV: "",
      statusV: "",
      idV: "",
      idU: "",
      orderStatus: "",
      bundles: [],
      items: [],
      compareItems: [],
      compareFields: [
        {
          key: "name",
          label: "name",
        },
        {
          key: "selling",
          label: "selling",
        },
        {
          key: "buying",
          label: "buying",
        },
        {
          key: "identical",
          label: "identical",
        },
      ],
      fields: [
        {
          key: "product",
          label: this.$t("Table_Head.Product"),
        },
        {
          key: "qty",
          label: this.$t("Table_Head.Quantity"),
        },
        {
          key: "price",
          label: this.$t("Table_Head.Price"),
        },
        {
          key: "total",
          label: this.$t("Table_Head.Total"),
        },
        {
          key: "status",
          label: this.$t("Table_Head.Status"),
        },
        {
          key: "is_portal",
          label: "التصنيف",
        },
        {
          key: "identical",
          label: this.$t("Table_Head.Identical"),
        },
        {
          key: "CompareReports",
          label: this.$t("Table_Head.Compare_Report"),
        },
      ],
      identicalType: false,
      shipData: null,
      shipPDF: null,
      id_images: {
        id_front_image: null,
        id_back_image: null,
      },
    };
  },
  async created() {
    this.id = this.$route.params.id;
    await this.fetchData();
  },
  methods: {
    refresh() {
axios
        .get("orders/" + this.id + "/checkProcessingOrderTransaction")
        .then((result) => {
          this.orderData = result.data.data;
          if (this.orderData.air_way_bill_number != null) {
            this.getPDF();
          }
          if (this.orderData.deleted_at != null) {
            this.delUser = false;
          } else {
            this.delUser = true;
          }
          // this.bundles = this.orderData.bundles
          //
          if (this.orderData.coupon != null) {
            this.discount = this.orderData.coupon.percentage;
          } else {
            this.discount = "-";
          }
          this.orderStatus = this.orderData.orderstatus.name;
          // this.idU = this.orderData.user.id
          // this.firstnameU = this.orderData.user.firstname
          // this.lastnameU = this.orderData.user.lastname
          // this.addressU = this.orderData.user.address
          // this.phoneU = this.orderData.user.phone
          // this.emailU = this.orderData.user.email
          if (this.orderData.vendor != null) {
            this.statusV = this.orderData.vendor.status.name;
            this.idV = this.orderData.vendor.id;
            this.firstnameV = this.orderData.vendor.firstname;
            this.lastnameV = this.orderData.vendor.lastname;
            this.addressV = this.orderData.vendor.address;
            this.phoneV = this.orderData.vendor.phone;
            this.emailV = this.orderData.vendor.email;
            this.storename = this.orderData.vendor.storename;
          }
          this.items = [];
          for (let index in this.orderData.variants) {
            this.items.push({
              product: this.orderData.variants[index].name,
              qty: this.orderData.variants[index].quantity,
              price: this.orderData.variants[index].price,
              total:
                this.orderData.variants[index].price *
                this.orderData.variants[index].quantity,
              status: this.orderData.variants[index].item_status_slug,
              identical: this.orderData.variants[index].identical,
              CompareReports: this.orderData.variants[index].reports,
              is_portal: this.orderData.variants[index].is_portal,
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    saveId() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.id_images.id_front_image != null) {
            if (this.id_images.id_front_image.includes(",")) {
              this.id_images.id_front_image = this.id_images.id_front_image
                .split(",")
                .map((item) => item.trim());
              this.id_images.id_front_image = this.id_images.id_front_image[1];
            }
          } else {
            delete this.id_images.id_front_image;
          }
          if (this.id_images.id_back_image != null) {
            if (this.id_images.id_back_image.includes(",")) {
              this.id_images.id_back_image = this.id_images.id_back_image
                .split(",")
                .map((item) => item.trim());
              this.id_images.id_back_image = this.id_images.id_back_image[1];
            }
          } else {
            delete this.id_images.id_back_image;
          }

          axios
            .post(
              "orders/" + this.id + "/e-contract/image/upload",
              this.id_images
            )
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.fetchData();
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage2(files[0]);
    },
    createImage2(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.id_images.id_front_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    // encode id back picture
    uploadImage3(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage3(files[0]);
    },
    createImage3(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.id_images.id_back_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getPDF() {
      axios
        .get(
          "orders/awb/" + this.orderData.air_way_bill_number + "/pdf/generate"
        )
        .then((result) => {
          this.shipPDF = result.data.data.ReportDoc;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    async fetchData() {
      await axios
        .get("orders/" + this.id)
        .then((result) => {
          this.orderData = result.data.data;
          if (this.orderData.air_way_bill_number != null) {
            this.getPDF();
          }
          if (this.orderData.deleted_at != null) {
            this.delUser = false;
          } else {
            this.delUser = true;
          }
          // this.bundles = this.orderData.bundles
          //
          if (this.orderData.coupon != null) {
            this.discount = this.orderData.coupon.percentage;
          } else {
            this.discount = "-";
          }
          this.orderStatus = this.orderData.orderstatus.name;
          // this.idU = this.orderData.user.id
          // this.firstnameU = this.orderData.user.firstname
          // this.lastnameU = this.orderData.user.lastname
          // this.addressU = this.orderData.user.address
          // this.phoneU = this.orderData.user.phone
          // this.emailU = this.orderData.user.email
          if (this.orderData.vendor != null) {
            this.statusV = this.orderData.vendor.status.name;
            this.idV = this.orderData.vendor.id;
            this.firstnameV = this.orderData.vendor.firstname;
            this.lastnameV = this.orderData.vendor.lastname;
            this.addressV = this.orderData.vendor.address;
            this.phoneV = this.orderData.vendor.phone;
            this.emailV = this.orderData.vendor.email;
            this.storename = this.orderData.vendor.storename;
          }
          this.items = [];
          for (let index in this.orderData.variants) {
            this.items.push({
              product: this.orderData.variants[index].name,
              qty: this.orderData.variants[index].quantity,
              price: this.orderData.variants[index].price,
              total:
                this.orderData.variants[index].price *
                this.orderData.variants[index].quantity,
              status: this.orderData.variants[index].item_status_slug,
              identical: this.orderData.variants[index].identical,
              CompareReports: this.orderData.variants[index].reports,
              is_portal: this.orderData.variants[index].is_portal,
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    selectRow(index, identical) {
      this.identicalType = identical;
      this.compareItems = [];
      for (let inde in this.orderData.variants[index].compareResult) {
        this.compareItems.push({
          name: Object.keys(
            this.orderData.variants[index].compareResult[inde]
          )[0],
          selling: this.orderData.variants[index].compareResult[inde][
            Object.keys(this.orderData.variants[index].compareResult[inde])[0]
          ].selling,
          buying: this.orderData.variants[index].compareResult[inde][
            Object.keys(this.orderData.variants[index].compareResult[inde])[0]
          ].buying,
          identical: this.orderData.variants[index].compareResult[inde][
            Object.keys(this.orderData.variants[index].compareResult[inde])[0]
          ].identical,
        });
      }
    },
    storeLogger(id) {
      axios
        .post("orders/" + id + "/logger/store", this.loggerNote)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    deleteOrder(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete vendor.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("orders/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteMobile(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete mobile.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("vendors/number/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    restoreData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore vendor.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("vendors/" + id + "/restore")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style scoped>
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.cardH {
  margin-bottom: 0px;
}
@media only screen and (max-width: 425px) {
  .table th,
  .table td {
    font-size: 12px;
    padding: opx !important;
  }
}
</style>
